<template>
  <Footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <div class="footer__top">
            <a href="#" class="footer__logo"><img src="@/assets/images/logo.png" alt="logo"></a>
          </div>
          <div class="footer__middle row">
            <div class="col-md-4">
              <ul class="footer__list" v-if="textBlock && textBlock['footer'] && textBlock['footer'].contacts">
                <li class="footer__item">
                  <!--                  <a href="#">Why Pay more? Rxeed it!</a>-->
                  <p v-html="textBlock['footer'].contacts"></p>
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <ul class="footer__list">
                <li class="footer__item">
                  <a href="#">Independent Pharmacy</a>
                </li>
                <li class="footer__item">
                  <a href="#">Wholesaler</a>
                </li>
                <li class="footer__item">
                  <a href="#">Provider</a>
                </li>
                <li class="footer__item">
                  <a href="#">Secure</a>
                </li>
                <li class="footer__item">
                  <a href="#">Express Returns</a>
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <ul class="footer__list">
                <li class="footer__item">
                  <a :href="require(`@/assets/PRIVACY_POLICY.pdf`)" target="_blank">Privacy Policy</a>
                </li>
                <li class="footer__item">
                  <a href="#">Commercial Use Agreement</a>
                </li>
                <li class="footer__item">
                  <a :href="require('@/assets/TERMS_AND_CONDITIONS.pdf')" target="_blank">Terms And Conditions</a>
                </li>
                <li class="footer__item">
                  <a href="#">Accessibility Statemen</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="dscsa col-md-3">
          <div class="dscsa__logo">
            <inline-svg :src="require(`@/assets/images/svg/dscsa-logo.svg`)"/>
          </div>
          <div class="dscsa__text">
            Dispenser-to-Dispenser Transactions
            for a Specific Patient Need
            Transaction information (TI)
            Transaction histore (TH)
            Transaction statement (TS)
          </div>
        </div>
        <div class="col-md-12">
          <div class="footer__bottom row">
            <div class="col-md-3" v-if="textBlock && textBlock.footer && textBlock.footer.copyright">
              <p>
                {{textBlock.footer.copyright}}
              </p>
            </div>
            <div class="soc col-md-9">
              <ul class="soc__list" v-if="socialLinks">
                <li class="soc__item" v-for="(item, index) in socialLinks.data" :key="index">
                  <a target="_blank" :href="item.url" style="color: white"><img :src="require(`@/assets/images/${item.name}-ic.png`)" alt="linkedIn"></a>
                  <!--                  <a href="#"><img src="@/assets/images/linkedin-ic.png" alt="linkedIn"></a>-->
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Footer>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props:['footer'],
  data (){
    return{
      privacy : '../../asets/PRIVACY_POLICY.pdf'
    }
  },
  computed:{
    ...mapGetters({
      socialLinks: 'landing/socialLinks',
      textBlock: 'landing/textBlock',
    })
  },
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.footer{
  background-color: $grey;
  padding: 20px 0 30px;
  &__top{
    margin-bottom: 30px;
  }
  &__logo{
    width: 260px;
    display: inline-block;
    img{
      width: 100%;
    }
  }
  &__list{
    margin-bottom: 20px;
  }
  &__item{
    a,p{
      color: white;
      font-size: 16px;
      line-height: 29px;
      font-family: 'Inter', Arial;
    }
  }
  &__bottom{
    border-top: 1px solid $black;
    padding-top: 15px;
    p{
      color: white;
      font-size: 12px;
      line-height: 14.25px;
      margin-bottom: 0;
      font-family: 'Inter', Arial;
    }
  }
}
.soc{
  &__list{
    display: flex;
    justify-content: flex-end;
  }
  &__item{
    height: 35px;
    width: 35px;
    margin: 0 5px;
    &:first-child{
      height: 37px;
      width: 37px;
    }
    &:nth-child(2){
      height: 32px;
      width: 32px;
    }
    img{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
</style>
